import React from 'react';
import './event-detail.css';
import GoBackButton from '../../elements/go-back-button/GoBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { EventType } from '../../state/events-reducer';

function parseDateString(dateString: string): Date {
  const isoString = dateString.replace(' ', 'T');
  return new Date(isoString);
}

function formatEventDate(date: Date) {
  const day = date.toLocaleString('ru-RU', { day: '2-digit' });
  let monthShort = date.toLocaleString('ru-RU', { month: 'short' });
  monthShort = monthShort.replace('.', '');
  const time = date.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return { day, monthShort, time };
}

const EventDetail: React.FC = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const event: EventType | undefined = useSelector((state: RootStateType) =>
    state.events.events.find((e) => e.id === Number(eventId))
  );

  if (!event) {
    return (
      <div className="event-detail">
        <GoBackButton />
        <h2>Событие не найдено</h2>
      </div>
    );
  }

  const dateObj = parseDateString(event.event_date);
  const { day, monthShort, time } = formatEventDate(dateObj);

  return (
    <div className="event-detail">
      <GoBackButton />

      <div className="event-detail__container">
        <div className="event-card">
          <div className="event-card__image-wrapper">
            <img
              className="event-card__image"
              src={
                event.photos[0]?.image ||
                'https://i.pinimg.com/736x/76/16/2a/76162ad3ca9370ee2eb19e261144b122.jpg'
              }
              alt={event.title}
            />

            <div className="event-card__date">
              <div className="event-card__date-day">{day}</div>
              <div className="event-card__date-month-time">
                {monthShort}
                <span>{time}</span>
              </div>
            </div>

            <div className="event-card__overlay">
              <div className="event-card__category">{event.category}</div>
              <div className="event-card__info">
                <div className="event-card__title">{event.title}</div>
                <div className="event-card__price">{event.price}₸</div>
              </div>
            </div>
          </div>

          <div className="event-card__description-block">
            <div className="event-detail__organizer">
              <img className="event-detail__organizer-logo" src='static/images/shop.png' />
              <p className='event-detail__organizer-name'>{event.organizer_name}</p>
            </div>
            <h4 className="event-card__description-title">Описание</h4>
            <p className="event-card__description-text">{event.description}</p>
          </div>
        </div>

        <div className="event-detail__secondary-card">
          <div className="event-detail__tickets">
            <p className="event-detail__section-title">Наличие билетов</p>
            <p className="event-detail__tickets-amount">
              {event.remaining_tickets} из {event.total_tickets}
            </p>
          </div>

          <div className="event-detail__address">
            <p className="event-detail__section-title">Адрес</p>
            <p className="event-detail__address-info">{event.location}</p>
          </div>

          <div className="event-detail__contacts">
            <p className="event-detail__section-title">Контакты</p>
            <p className="event-detail__contacts-info">{event.contact_info}</p>
          </div>
        </div>

        <button className='event-detail__button' onClick={() => navigate(`/events/${event.id}/buy-ticket`)}>Купить билет</button>
      </div>
    </div>
  );
};

export default EventDetail;
