import React from 'react'
import Header from '../components/header/Header'
import BuyTicketForm from '../components/buy-ticket-form/BuyTicketForm'
import Navbar from '../components/navbar/Navbar'

const BuyTicketPage = () => {
  return (
    <div className='page buy-ticket-page'>
        <div className="sticky-container">
        <Header />
        <Navbar />
      </div>
        <BuyTicketForm />
    </div>
  )
}

export default BuyTicketPage