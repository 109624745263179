import React from 'react'
import Header from '../components/header/Header'
import ActiveTickets from '../components/tickets/ActiveTickets'
import Navbar from '../components/navbar/Navbar'


const ActiveTicketsPage = () => {
  return (
    <div className='page tickets-page'>
        <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

        <ActiveTickets />
    </div>
  )
}

export default ActiveTicketsPage