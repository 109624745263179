import React from 'react';
import { TicketType } from '../../state/user-reducer';
import './ticket-item.css';
import { useNavigate } from 'react-router-dom';

type TicketItemPropsType = {
  ticket: TicketType;
};

function getStatusProps(status: string) {
  switch (status) {
    case 'paid':
      return { color: 'green', label: 'Оплачен' };
    case 'voided':
      return { color: 'orange', label: 'Возврат' };
    case 'cancelled':
      return { color: 'red', label: 'Отменён' };
    default:
      return { color: '#333', label: status }; 

  }
}
const TicketItem: React.FC<TicketItemPropsType> = ({ ticket }) => {
  const { event_title, total_price, event_date, tickets, status } = ticket;
  const qrCodeUrl = tickets[0]?.qr_code || '';
  const { color, label } = getStatusProps(status);
  const navigate = useNavigate();

  return (
    <div className="ticket-item" onClick={() => navigate('/tickets/' + ticket.id)}>
      <div className="ticket-item__info">
        <p className="ticket-item__title">{event_title}</p>
        <p className="ticket-item__price">{total_price}₸</p>
        <p className="ticket-item__date">{event_date}</p>
      </div>

      <div className="ticket-item__qr-block">
        <img
          className="ticket-item__qr-image"
          src={qrCodeUrl}
          alt="QR Code"
        />
        <p 
          className="ticket-item__status" 
          style={{ color }}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

export default TicketItem;
