import { useSelector } from 'react-redux';
import './events.css';
import { RootStateType } from '../../state/store';
import { Link } from 'react-router-dom';
import EventsList from '../events-list/EventsList';
import { useMemo, useState } from 'react';
import { EventType } from '../../state/events-reducer';

const Events = () => {
  const events: EventType[] = useSelector(
    (state: RootStateType) => state.events.events
  );

  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOption, setSortOption] = useState('name');

  const categories = [
    { value: '', label: 'Все категории' },
    { value: 'music', label: 'Музыка' },
    { value: 'sport', label: 'Спорт' },
    { value: 'art', label: 'Искусство' },
    { value: 'entertainment', label: 'Развлечения' },
    { value: 'education', label: 'Образование' },
  ];

  const processedEvents = useMemo(() => {
    let filtered = events;
    if (selectedCategory) {
      filtered = filtered.filter(
        (event) => event.category === selectedCategory
      );
    }

    let sorted = [...filtered];

    switch (sortOption) {
      case 'name':
        sorted.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'date':
        sorted.sort(
          (a, b) =>
            new Date(a.event_date).getTime() - new Date(b.event_date).getTime()
        );
        break;
      case 'price':
        sorted.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        break;
      default:
        break;
    }

    return sorted;
  }, [events, selectedCategory, sortOption]);

  return (
    <div className="events">
      <div className="events-list-top">
        <p className="events-list-title">Мероприятия</p>
        <Link to={'/tickets'} className="your-tickets">
          Ваши билеты
        </Link>
      </div>

      <div className="events-list-controls">
      <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map((cat) => (
            <option key={cat.value} value={cat.value}>
              {cat.label}
            </option>
          ))}
        </select>

        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="name">Сортировать по названию</option>
          <option value="date">Сортировать по дате</option>
          <option value="price">Сортировать по цене</option>
        </select>
      </div>

      <EventsList events={events} />
    </div>
  );
};

export default Events;
