import React, { useEffect, useState } from 'react';
import {
  makePaymentRequest,
  makePaymentRequestWithSavedCard,
} from '../../utils/fetch';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import {
  changeModalTypeAC,
  deleteSavedCardTC,
  getSavedCardsTC,
  modalEnumType,
  SavedCard,
  setAlertAC,
} from '../../state/user-reducer';
import CreditCardForm from '../credit-card-form/CreditCardForm';
import { moveCartItemToActiveAC } from '../../state/cart-reducer';
import { cardTypeIcons } from '../../config';

const CreditCardModal = () => {
  const dispatch = useDispatch<any>();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const savedCards = useSelector<RootStateType, SavedCard[]>(
    (state) => state.userData.savedCards
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [addNewCard, setAddNewCard] = useState(true);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  // Handle selecting a saved card, and toggle if already selected
  const handleSavedCardSelect = (cardId: number) => {
    setSelectedCardId((prevSelectedCardId) =>
      prevSelectedCardId === cardId ? null : cardId
    );
    setAddNewCard(() => (selectedCardId === cardId ? true : false));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!window.tiptop) {
      setIsLoading(false);
      return;
    }

    try {
      let paymentResponse;

      if (selectedCardId) {
        // Оплата сохраненной картой
        paymentResponse = await makePaymentRequestWithSavedCard(
          Number(orderId),
          selectedCardId
        );
      } else {
        // Оплата новой картой
        const checkout = new window.tiptop.Checkout({
          publicId: 'pk_cf85675adc462dbe96083259857ea',
          container: document.getElementById('paymentFormSample'),
        });

        const cryptogram = await checkout.createPaymentCryptogram();
        const nameInput = document.querySelector(
          'input[data-cp="name"]'
        ) as HTMLInputElement;
        const name = nameInput ? nameInput.value : '';

        paymentResponse = await makePaymentRequest(
          cryptogram,
          name,
          Number(orderId)
        );
      }

      if (
        paymentResponse?.Status === 'Completed' ||
        paymentResponse?.message === 'Payment succeeded!'
      ) {
        dispatch(
          setAlertAC(
            paymentResponse.CardHolderMessage || 'Оплата прошла успешно',
            'success'
          )
        );
        dispatch(getSavedCardsTC());
        dispatch(changeModalTypeAC(modalEnumType.NONE));
        dispatch(moveCartItemToActiveAC(Number(orderId)));
        navigate('/active-orders');
      } else {
        dispatch(
          setAlertAC(
            paymentResponse?.CardHolderMessage ||
              'Ошибка при выполнении платежа.',
            'error'
          )
        );
      }
    } catch (error) {
      console.error('Error:', error);
      dispatch(setAlertAC('Ошибка при выполнении платежа.', 'error'));
    } finally {
      setIsLoading(false);
    }
  };

  // Загружаем скрипт TipTop Pay при монтировании компонента
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.tiptoppay.kz/checkout.js';
    script.async = true;
    script.onload = () => {
      if (!window.tiptop) {
        setIsLoading(false);
        return;
      }
    };
    document.head.appendChild(script);
  }, []);

  return (
    <div className="credit-card-modal">
      {/* Если есть сохраненные карты, показываем их список */}
      {savedCards.length > 0 && (
        <div className="credit-card-modal-saved-cards">
          <h4>Выберите сохраненную карту</h4>
          <div className="saved-cards-list">
            {savedCards.map((card: SavedCard) => (
              <div className="saved-card-item" key={card.id}>
                <div
                  className="card-info"
                  onClick={() => handleSavedCardSelect(card.id)}
                >
                  <img
                    src={`../../static/images/${
                      cardTypeIcons[card.card_type] || ''
                    }`}
                    alt={card.card_type}
                    className="card-icon"
                  />
                  <p>
                    {card.card_type}
                    <span>••••</span> {card.card_last_four}
                  </p>
                  {selectedCardId === card.id && (
                    <span>
                      <CheckIcon />
                    </span>
                  )}
                </div>

                <div
                  className="delete-saved-card"
                  onClick={() => {
                    dispatch(deleteSavedCardTC(card.id));
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {!addNewCard ? (
        <button
          className="add-new-card"
          onClick={() => {
            setSelectedCardId(null);
            setAddNewCard(true);
          }}
        >
          Добавить новую карту
        </button>
      ) : (
        <CreditCardForm
          disabled={!!selectedCardId}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          setIsFormValid={setIsFormValid}
        />
      )}
      <button type="submit" className="proceed-btn" onClick={handleSubmit} disabled={isLoading || (!isFormValid && !!!selectedCardId)}>
        {isLoading ? <CircularProgress size={24} color='info'/> : 'Оплатить'}
      </button>
    </div>
  );
};

export default CreditCardModal;
