import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import GoBackButton from '../../elements/go-back-button/GoBackButton';
import CreditCardForm from '../credit-card-form/CreditCardForm';
import {
  makePaymentRequest,
  makePaymentRequestWithSavedCard,
} from '../../utils/fetch';
import {
  changeModalTypeAC,
  deleteSavedCardTC,
  getSavedCardsTC,
  modalEnumType,
  SavedCard,
  setAlertAC,
} from '../../state/user-reducer';
import { moveCartItemToActiveAC } from '../../state/cart-reducer';
import { cardTypeIcons } from '../../config';
import './buy-ticket-form.css';
import { RootStateType } from '../../state/store';
import { EventType } from '../../state/events-reducer';

const BuyTicketForm = () => {
  const dispatch = useDispatch<any>();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const savedCards = useSelector<RootStateType, SavedCard[]>(
    (state) => state.userData.savedCards
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [addNewCard, setAddNewCard] = useState(true);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const currentEvent = useSelector<RootStateType, EventType | undefined>(
    (state) => state.events.events.find((event) => event.id === Number(eventId))
  );

  const handleSavedCardSelect = (cardId: number) => {
    setSelectedCardId((prev) => (prev === cardId ? null : cardId));
    setAddNewCard(selectedCardId === cardId ? true : false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!window.tiptop) {
      setIsLoading(false);
      return;
    }

    try {
      let paymentResponse;

      if (selectedCardId) {
        paymentResponse = await makePaymentRequestWithSavedCard(
          Number(eventId),
          selectedCardId
        );
      } else {
        const checkout = new window.tiptop.Checkout({
          publicId: 'pk_cf85675adc462dbe96083259857ea',
          container: document.getElementById('credit-card'),
        });

        const cryptogram = await checkout.createPaymentCryptogram();
        const nameInput = document.querySelector(
          'input[data-cp="name"]'
        ) as HTMLInputElement;
        const name = nameInput ? nameInput.value : '';

        paymentResponse = await makePaymentRequest(
          cryptogram,
          name,
          Number(eventId)
        );
      }

      if (
        paymentResponse?.Status === 'Completed' ||
        paymentResponse?.message === 'Payment succeeded!'
      ) {
        dispatch(
          setAlertAC(
            paymentResponse.CardHolderMessage || 'Оплата прошла успешно',
            'success'
          )
        );
        dispatch(getSavedCardsTC());
        dispatch(moveCartItemToActiveAC(Number(eventId)));
        navigate('/active-orders');
      } else {
        dispatch(
          setAlertAC(
            paymentResponse?.CardHolderMessage ||
              'Ошибка при выполнении платежа.',
            'error'
          )
        );
      }
    } catch (error) {
      console.error('Error:', error);
      dispatch(setAlertAC('Ошибка при выполнении платежа.', 'error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.tiptoppay.kz/checkout.js';
    script.async = true;
    document.head.appendChild(script);
  }, []);

  return (
    <div className="buy-ticket">
      <GoBackButton />
      <p className="buy-ticket-title">Оплата</p>
      <div className="buy-ticket-form">
        <div className="payment-section">
          <h2 className="payment-section__label">Продукты</h2>
          <p className="payment-section__value">
            {currentEvent?.title}
          </p>
        </div>
        <div className="payment-section double">
          <h2 className="payment-section__label">Цена</h2>
          <p className="payment-section__value">{currentEvent?.price}₸</p>
        </div>
        <div className="payment-subtitle">Ваши сохраненные карты</div>
        {savedCards.length > 0 ? (
          <div className="saved-cards-list">
            {savedCards.map((card) => (
              <div className="saved-card-item" key={card.id}>
                <div
                  className="card-info"
                  onClick={() => handleSavedCardSelect(card.id)}
                >
                  <img
                    src={`../../static/images/${
                      cardTypeIcons[card.card_type] || ''
                    }`}
                    alt={card.card_type}
                    className="card-icon"
                  />
                  <p>
                    {card.card_type}
                    <span>••••</span> {card.card_last_four}
                  </p>
                  {selectedCardId === card.id && (
                    <span>
                      <CheckIcon />
                    </span>
                  )}
                </div>

                <div
                  className="delete-saved-card"
                  onClick={() => {
                    // dispatch(deleteSavedCardTC(card.id));
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='no-saved-cards'>Нет сохраненных карт</div>
        )}
        {!addNewCard ? (
          <button
            className="payment-new-card"
            onClick={() => setAddNewCard(true)}
          >
            Использовать новую карту
          </button>
        ) : (
          <CreditCardForm
            disabled={!!selectedCardId}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            setIsFormValid={setIsFormValid}
          />
        )}
        <button
          className="payment-submit"
          onClick={handleSubmit}
          disabled={isLoading || (!isFormValid && !selectedCardId)}
        >
          {isLoading ? <CircularProgress size={24} color="info" /> : 'Оплатить'}
        </button>
      </div>
    </div>
  );
};

export default BuyTicketForm;
