import React from 'react'
import Header from '../components/header/Header'
import Events from '../components/events/Events'
import Navbar from '../components/navbar/Navbar'

const EventsPage = () => {
  return (
    <div className="page events-page">
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>
      <Events />
    </div>
  )
}

export default EventsPage