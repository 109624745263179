import './tickets.css';
import GoBackButton from '../../elements/go-back-button/GoBackButton';
import TicketsList from '../tickets-list/TicketsList';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { TicketType } from '../../state/user-reducer';
import { useNavigate } from 'react-router-dom';

const ActiveTickets = () => {
  const navigate = useNavigate();
  const tickets = useSelector<RootStateType, TicketType[]>(
    (state) => state.userData.tickets.active
  );

  return (
    <div className="tickets-container">
      <GoBackButton />
      <div className="tickets-header">
        <p className="tickets-header__title">Ваши билеты</p>
        <p className="tickets-header__archive" onClick={() => navigate('/tickets/archive')}>Архив</p>
      </div>

      <TicketsList tickets={tickets} />
    </div>
  );
};

export default ActiveTickets;
