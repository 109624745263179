import React from 'react'
import Header from '../components/header/Header'
import Navbar from '../components/navbar/Navbar'
import TicketDetail from '../components/ticket-detail/TicketDetail'

const TicketDetailPage = () => {
    return (
        <div className="page ticket-detail-page">
          <div className="sticky-container">
            <Header />
            <Navbar />
          </div>
    
          <TicketDetail />
        </div>
      )
}

export default TicketDetailPage