import axios from 'axios';
import { Dispatch } from 'redux';
import { mainURL } from '../config';

export enum EventEnumType {
  SET_EVENTS = 'SET_EVENTS',
  ADD_EVENT = 'ADD_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',
  SET_EVENT_TICKETS = 'SET_EVENT_TICKETS',
}

interface EventPhoto {
  id: number;
  image: string;
  event: number;
}

export interface EventType {
  id: number;
  title: string;
  description: string;
  organizer_name: string;
  contact_info: string;
  location: string;
  latitude: string;
  longitude: string;
  event_date: string;
  price: string;
  remaining_tickets: number;
  total_tickets: number;
  photos: EventPhoto[];
  is_active: boolean;
  category: string;
  current_order: number | null;
}

export type SetEventActionType = {
  type: EventEnumType.SET_EVENTS;
  events: EventType[];
};


type EventsActionType = SetEventActionType;

type EventsStateType = {
  events: EventType[];
}

const initialState: EventsStateType = {
  events: [],
};

export const eventsReducer = (
  state: EventsStateType = initialState,
  action: EventsActionType
) => {
  switch (action.type) {
    case EventEnumType.SET_EVENTS:
      return {
        ...state,
        events: action.events, 
      };

    default:
      return state;
  }
};

export const setEventsAC = (events: EventType[]): SetEventActionType => ({
  type: EventEnumType.SET_EVENTS,
  events,
});


export const getEventsTC = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(mainURL + '/en/api/v1/events/');
      console.log('events', response.data);

      dispatch(setEventsAC(response.data.results));
    } catch (error) {
      console.error('Failed to get events:', error);
    }
  };
};
