import './navbar.css';
import { NavLink } from 'react-router-dom'; // Import NavLink for navigation
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from '../hoc/useAuth';
import { useState } from 'react';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

// Компонент навигации
const Navbar = () => {
  const [aboutUs, setAboutUs] = useState(false);
  // Получаем данные о состоянии авторизации и функцию выхода из системы
  const { isAuthenticated } = useAuth();

  // Проверяем, авторизован ли пользователь
  if (!isAuthenticated) return null; // Если пользователь не авторизован, не рендерим ничего

  // Функция для генерации класса активной ссылки
  const getActiveClass = ({ isActive }: { isActive: boolean }) =>
    isActive ? 'active' : '';

  return (
    <nav className="nav-content">
      <ul className="nav-list">
        <li>
          {/* Ссылка на главную страницу */}
          <NavLink to="/" className={getActiveClass}>
            <HomeOutlinedIcon />
            Главная
          </NavLink>
        </li>
        <li>
          {/* Ссылка на страницу поиска */}
          <NavLink to="/search" className={getActiveClass}>
            <SearchIcon />
            Поиск
          </NavLink>
        </li>
        <li>
          {/* Ссылка на чаты */}
          <NavLink to="/events" className={getActiveClass}>
            <SendOutlinedIcon style={{ rotate: '-45deg' }} />
            Ивенты
          </NavLink>
        </li>
        <li>
          {/* Ссылка на магазины */}
          <NavLink to="/shops" className={getActiveClass}>
            <StorefrontIcon />
            Магазины
          </NavLink>
        </li>
        <li>
          {/* Ссылка на профиль */}
          <NavLink to="/profile" className={getActiveClass}>
            <AccountCircleIcon /> Профиль
          </NavLink>
        </li>
        {/* <li >
          <NavLink to="" className={getActiveClass} onClick={() => setAboutUs((prev) => !prev)}>
            <InfoIcon /> О Нас
          </NavLink>
          <div className={aboutUs ? 'about-us-box active' : 'about-us-box'}>
            <h3>Компания: ИП FOODCLOUD</h3>
            <p>
              <strong>Адрес:</strong> Казахстан, Алматы, АЛЬ ФАРАБИ, дом 15
            </p>
            <p>
              <strong>БИН:</strong> 940930300041
            </p>
            <p>
              <strong>Банк:</strong> АО "Kaspi Bank"
            </p>
            <p>
              <strong>КБе:</strong> 19
            </p>
            <p>
              <strong>БИК:</strong> CASPKZKA
            </p>
            <p>
              <strong>Номер счёта:</strong> KZ94722S000017978652
            </p>
          </div>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
