import React from 'react';
import Header from '../components/header/Header';
import ArchiveTickets from '../components/tickets/ArchiveTickets';
import Navbar from '../components/navbar/Navbar';

const ArchiveTicketsPage = () => {
  return (
    <div className="page archive-tickets-page">
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <ArchiveTickets />
    </div>
  );
};

export default ArchiveTicketsPage;
