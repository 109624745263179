import { applyMiddleware, combineReducers, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { CategoryReducer } from './category-reducer';
import { ProductReducer } from './product-reducer';
import { SubcategoryReducer } from './subcategory-reducer';
import { cartReducer } from './cart-reducer';
import { userReducer } from './user-reducer';
import { shopsReducer } from './shops-reducer';
import { eventsReducer } from './events-reducer';

const rootReducer = combineReducers({
  categories: CategoryReducer,
  products: ProductReducer,
  cartItems: cartReducer,
  subcategories: SubcategoryReducer,
  userData: userReducer,
  shopData: shopsReducer,
  events: eventsReducer
});

export const store = createStore(
  rootReducer,
  undefined,
  applyMiddleware(thunk)
);

export type RootStateType = ReturnType<typeof rootReducer>;
