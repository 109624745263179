import { useParams } from 'react-router-dom';
import './ticket-detail.css';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { TicketType } from '../../state/user-reducer';
import GoBackButton from '../../elements/go-back-button/GoBackButton';

function parseDateString(dateString: string): Date {
  const isoString = dateString.replace(' ', 'T');
  return new Date(isoString);
}

function formatEventDate(date: Date) {
  const day = date.toLocaleString('ru-RU', { day: '2-digit' });
  let monthShort = date.toLocaleString('ru-RU', { month: 'short' });
  monthShort = monthShort.replace('.', '');
  const time = date.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return { day, monthShort, time };
}

function getStatusProps(status: string) {
  switch (status) {
    case 'valid':
      return { color: 'green', label: 'Билет активный' };
    // case 'canceled':
    //   return { color: 'orange', label: 'Возврат' };
    case 'canceled':
      return { color: 'red', label: 'Билет неактивный' };
    default:
      return { color: '#333', label: status };
  }
}

const TicketDetail = () => {
  const { ticketId } = useParams<{ ticketId: string }>();

  const tickets = useSelector((state: RootStateType) => state.userData.tickets);

  const id = Number(ticketId);

  const ticket: TicketType | undefined =
    tickets.active.find((t: TicketType) => t.id === id) ||
    tickets.archive.find((t: TicketType) => t.id === id);

  if (!ticket) {
    return (
      <div className="event-detail">
        <GoBackButton />
        <h2>Событие не найдено</h2>
      </div>
    );
  }

  const dateObj = parseDateString(ticket.event_date);
  const { day, monthShort, time } = formatEventDate(dateObj);

  const qrTicket = ticket.tickets[0];
  const { color, label } = getStatusProps(ticket.tickets[0].status);

  return (
    <div className="event-detail">
      <GoBackButton />

      <div className="event-detail__container">
        <div className="event-card">
          <div className="event-card__image-wrapper">
            <img
              className="event-card__image"
              src="https://i.pinimg.com/736x/76/16/2a/76162ad3ca9370ee2eb19e261144b122.jpg"
              alt={ticket.event_title}
            />

            <div className="event-card__date">
              <div className="event-card__date-day">{day}</div>
              <div className="event-card__date-month-time">
                {monthShort}
                <span>{time}</span>
              </div>
            </div>

            <div className="event-card__overlay">
              <div className="event-card__info">
                <div className="event-card__title">{ticket.event_title}</div>
                <div className="event-card__price">{ticket.total_price}₸</div>
              </div>
            </div>
          </div>

          <div className="event-card__description-block">
            <div className="event-detail__organizer">
              <img
                className="event-detail__organizer-logo"
                src="static/images/shop.png"
                alt="Organizer Logo"
              />
              <p className="event-detail__organizer-name">{ticket.user}</p>
            </div>
            <h4 className="event-card__description-title">Описание</h4>
            <p className="event-card__description-text">{ticket.event_title}</p>
          </div>
        </div>

        <div className="event-detail__secondary-card">
          <div className="event-detail__address">
            <p className="event-detail__section-title">Адрес</p>
            <p className="event-detail__address-info">{ticket.event}</p>
          </div>

          <div className="event-detail__contacts">
            <p className="event-detail__section-title">Контакты</p>
            <p className="event-detail__contacts-info">{ticket.event_title}</p>
          </div>
        </div>

        <div className="event-detail__third-card">
          <p className="event-detail__section-title">Ваши билеты</p>
          <div className="ticket-qr">
            <img
              src={qrTicket.qr_code}
              alt="QR Code"
              className="ticket-qr__image"
            />
            <div className="ticket-status" style={{ color: color }}>
              {label}
            </div>
            <div className="ticket-actions">
              <button className="ticket-action-btn add-photo">
                Добавить фото
              </button>
              <button className="ticket-action-btn cancel-ticket">
                Отменить билет
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetail;
