import { EventType } from "../../state/events-reducer";
import "./events-list.css"
import { Link } from 'react-router-dom';

type EventsListPropsType = {
  events: EventType[];
};

function parseDateString(dateString: string): Date {
  const isoString = dateString.replace(' ', 'T');
  return new Date(isoString);
}

function formatEventDate(date: Date) {
  const day = date.toLocaleString('ru-RU', { day: '2-digit' });
  let monthShort = date.toLocaleString('ru-RU', { month: 'short' });
  monthShort = monthShort.replace('.', '');
  const time = date.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return { day, monthShort, time };
}

const EventsList = ({ events }: EventsListPropsType) => {
  return (
    <div className="events-container">
      {events.map((event) => {
        const dateObj = parseDateString(event.event_date);
        const { day, monthShort, time } = formatEventDate(dateObj);

        return (
          <Link
            key={event.id}
            to={`/events/${event.id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div key={event.id} className="event-card">
              <div className="event-card__image-wrapper">
                <img
                  className="event-card__image"
                  src={
                    event.photos[0]?.image ||
                    'https://i.pinimg.com/736x/76/16/2a/76162ad3ca9370ee2eb19e261144b122.jpg'
                  }
                  alt={event.title}
                />

                <div className="event-card__date">
                  <div className="event-card__date-day">{day}</div>
                  <div className="event-card__date-month-time">
                    {monthShort}
                    <span>{time}</span>
                  </div>
                </div>

                <div className="event-card__overlay">
                  <div className="event-card__category">{event.category}</div>
                  <div className="event-card__info">
                    <div className="event-card__title">{event.title}</div>
                    <div className="event-card__price">{event.price}₸</div>
                  </div>
                </div>
              </div>

              <div className="event-card__description-block">
                <h4 className="event-card__description-title">Описание</h4>
                <p className="event-card__description-text">
                  {event.description}
                </p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default EventsList;
