import React from 'react'
import Header from '../components/header/Header'
import EventDetail from '../components/event-detail/EventDetail'
import Navbar from '../components/navbar/Navbar'

const EventDetailPage = () => {
  return (
    <div className="page events-page">
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <EventDetail />
    </div>
  )
}

export default EventDetailPage