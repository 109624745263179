import { useSelector, useDispatch } from 'react-redux';
import { getProductsTC, ProductType } from '../../state/product-reducer';
import { RootStateType } from '../../state/store';
import './product-list.css';
import ProductItem from '../product-item/ProductItem';
import { CategoryType } from '../../state/category-reducer';
import { SubcategoryType } from '../../state/subcategory-reducer';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { filterProducts } from '../../utils/fetch';

type ProductListPropsType = {};

const ProductList = (props: ProductListPropsType) => {
  // Replace `any` with your proper dispatch type if available (for example, AppDispatch)
  const dispatch = useDispatch<any>();

  // Get products, active category and subcategory from Redux state
  const products = useSelector<RootStateType, ProductType[]>(
    (state) => state.products.products
  );
  const activeCategory = useSelector<RootStateType, CategoryType>(
    (state) => state.categories.activeCategory
  );
  const activeSubcategory = useSelector<RootStateType, SubcategoryType | null>(
    (state) => state.subcategories.activeSubcategory
  );

  // Local states for fade effect, current page and loading indicator for infinite scroll.
  const [fade, setFade] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(6);
  const [loading, setLoading] = useState<boolean>(false);

  // Memoize the filtered products so that the expensive filtering only runs when necessary.
  const filteredProducts = useMemo(() => {
    return filterProducts(products, activeCategory, activeSubcategory);
  }, [products, activeCategory, activeSubcategory]);

  // When filteredProducts change, trigger the fade effect.
  useEffect(() => {
    setFade(true);
    const timeout = setTimeout(() => {
      setFade(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [filteredProducts]);

  // Scroll handler: check if near the bottom and if so, update the current page.
  const handleScroll = useCallback(() => {
    // Check if user has scrolled to 100px from the bottom.
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      !loading
    ) {
      setLoading(true);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [loading, activeCategory]);

  // Attach the scroll listener.
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // When currentPage updates (beyond the first page) and activeCategory is "Популярное",
  // dispatch the getProductsTC thunk.
  useEffect(() => {
    if (currentPage > 1 && activeCategory?.name === 'Популярное') {
      (async () => {
        try {
          await dispatch(getProductsTC(currentPage));
        } catch (error) {
          console.error('Error fetching more products:', error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentPage, activeCategory, dispatch]);

  return (
    <div className="container">
      <div className={`product-list ${fade ? 'fade' : ''}`}>
        {filteredProducts.length === 0 ? (
          <div className="no-products">Нет продуктов для отображения</div>
        ) : (
          filteredProducts.map((product: ProductType, index: number) => (
            <ProductItem key={`${product.id}-${index}`} product={product} />
          ))
        )}
      </div>
      {/* {loading && <div className="loading">Загрузка продуктов...</div>} */}
    </div>
  );
};

export default ProductList;
