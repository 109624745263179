import React from 'react'
import GoBackButton from '../../elements/go-back-button/GoBackButton'
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { TicketType } from '../../state/user-reducer';
import TicketsList from '../tickets-list/TicketsList';

const ArchiveTickets = () => {
  const tickets = useSelector<RootStateType, TicketType[]>(
    (state) => state.userData.tickets.archive
  );

  return (
    <div className="tickets-container">
      <GoBackButton />
      <div className="tickets-header">
        <p className="tickets-header__title">Архив</p>
      </div>

      <TicketsList tickets={tickets} />
    </div>
  )
}

export default ArchiveTickets